import React, { Component } from 'react';
import Layout from '../components/layout';

export default class TropicalPage extends Component {
  render() {
    return (
      <Layout>
        <div className="tropical" id="bg" />
      </Layout>
    );
  }
}
